import url from '../data/url';
import * as imageTransform from '../imageService/imageTransform';
import { generate as generateGradient, } from '@wix/css-gradient-generator';
var MEDIA_TYPES = {
    VIDEO: 'WixVideo',
};
function getImageDisplayData(fittingType, alignType, imageUri, srcImageWidth, srcImageHeight, containerWidth, containerHeight, quality) {
    if (quality === void 0) { quality = 85; }
    var src = {
        id: imageUri,
        width: srcImageWidth,
        height: srcImageHeight,
    };
    var target = {
        width: containerWidth,
        height: containerHeight,
        alignment: alignType,
        htmlTag: 'bg',
        pixelAspectRatio: window.devicePixelRatio,
    };
    return imageTransform.getData(fittingType, src, target, {
        quality: quality,
    });
}
var getColorValueFromData = function (data, allowGradients) {
    var _a;
    if (allowGradients) {
        var fill = ((_a = data.colorLayers) === null || _a === void 0 ? void 0 : _a[0].fill) || data.color;
        return (fill === null || fill === void 0 ? void 0 : fill.type) === 'SolidColor' ? fill.color : fill;
    }
    return data.color;
};
var getBackgroundCSS = function (editorAPI, data, allowGradients) {
    if (allowGradients === void 0) { allowGradients = true; }
    var value = getColorValueFromData(data, allowGradients);
    if (typeof value === 'string') {
        return { backgroundColor: editorAPI.theme.colors.get(value) };
    }
    return {
        backgroundImage: generateGradient(value, {
            resolveColor: editorAPI.theme.colors.get,
        }),
    };
};
var getCurrentMedia = function (editorAPI, data, allowGradients) {
    var _a, _b;
    if (allowGradients === void 0) { allowGradients = true; }
    //TODO cant measure DOM, data is needed pre render
    var TOP_PANEL_WIDTH = 288;
    var TOP_PANEL_HEIGHT = 184;
    var style = {};
    var backgroundImageParts = [];
    var colorLayer = getBackgroundCSS(editorAPI, data, allowGradients);
    if (data === null || data === void 0 ? void 0 : data.mediaRef) {
        var imageData = ((_a = data.mediaRef) === null || _a === void 0 ? void 0 : _a.type) === MEDIA_TYPES.VIDEO
            ? data.mediaRef.posterImageRef
            : data.mediaRef;
        var previewDisplayData = getImageDisplayData(data.fittingType, data.alignType, imageData.uri, imageData.width, imageData.height, TOP_PANEL_WIDTH, TOP_PANEL_HEIGHT);
        style.opacity = imageData.opacity;
        Object.assign(style, (_b = previewDisplayData.css) === null || _b === void 0 ? void 0 : _b.container);
        backgroundImageParts.push("url(".concat(url.joinURL(
        // @ts-expect-error
        window.serviceTopology.staticMediaUrl, previewDisplayData.uri), ")"));
    }
    if (colorLayer.backgroundColor) {
        style.backgroundColor = colorLayer.backgroundColor;
    }
    else if (colorLayer.backgroundImage) {
        backgroundImageParts.push(colorLayer.backgroundImage);
    }
    if (backgroundImageParts.length) {
        style.backgroundImage = backgroundImageParts.join();
    }
    return style;
};
export { getImageDisplayData, getBackgroundCSS, getCurrentMedia };
