var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import * as redux from 'redux';
import * as reactRedux from 'react-redux';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '../../recompose';
import { createPropsDeepEqual } from '../propsDeepEqual';
import defaultWithErrorBoundary from '../errorBoundaryComponent';
import { withConnectedErrorBoundary } from './withConnectedErrorBoundary';
export var STORES = {
    STATE_ONLY: ['state'],
    DS_ONLY: ['dsRead'],
    EDITOR_API: ['state', 'dsRead', 'editorAPI', 'host'],
    STATE_AND_DS: ['state', 'dsRead'],
    MOUSE_OPS: ['state', 'dsRead', 'editorAPI', 'mouseops', 'host'],
};
var dsOnly = function (next, prev) {
    return next.state.viewerRendersCounter === prev.state.viewerRendersCounter;
};
var dsAndState = function (next, prev) {
    return dsOnly(next, prev) && next.state === prev.state;
};
var makeMapStateToProps = function (storesArr, mapStateToProps) {
    if (!_.isFunction(mapStateToProps)) {
        return null;
    }
    // NOTE: the number of declared mapStateToProps function parameters affects when it will be called.
    // https://react-redux.js.org/api/connect#parameters
    var dependsOnOwnProps = true;
    if (mapStateToProps.dependsOnOwnProps === false) {
        dependsOnOwnProps = false;
    }
    else if (mapStateToProps.length === 1) {
        dependsOnOwnProps = false;
    }
    var selector = function (combinedState) {
        var mbOwnProps = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            mbOwnProps[_i - 1] = arguments[_i];
        }
        //should use spread in case arguments.length === 1;
        var mappingDependencies = _.pick(combinedState, storesArr);
        return _.isFunction(mapStateToProps)
            ? mapStateToProps.apply(void 0, __spreadArray([mappingDependencies], mbOwnProps, false)) : {};
    };
    selector.dependsOnOwnProps = dependsOnOwnProps;
    return selector;
};
function isClassComponent(component) {
    var _a;
    return (typeof component === 'function' && !!((_a = component.prototype) === null || _a === void 0 ? void 0 : _a.isReactComponent));
}
var wrapForTests = function (Comp) {
    return /** @class */ (function (_super) {
        __extends(TestedComp, _super);
        function TestedComp() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        TestedComp.prototype.render = function () {
            return React.createElement(Comp, this.props);
        };
        return TestedComp;
    }(React.Component));
};
export var connect = function (storesArr, mapStateToProps, mapDispatchToProps, mergeProps, pure, withErrorBoundary) {
    if (pure === void 0) { pure = true; }
    if (withErrorBoundary === void 0) { withErrorBoundary = defaultWithErrorBoundary; }
    return function (Component) {
        if (process.env.NODE_ENV === 'test') {
            if (!isClassComponent(Component)) {
                // we need ref for tests, but fc dont have ref
                Component = wrapForTests(Component);
            }
        }
        var propsDeepEqual = createPropsDeepEqual(Component.displayName);
        var dsConnected = _.includes(storesArr, 'dsRead');
        var stateConnected = _.includes(storesArr, 'state');
        var editorAPIConnected = _.includes(storesArr, 'editorAPI');
        var mouseConnected = _.includes(storesArr, 'mouseops');
        var areStatePropsEqual = propsDeepEqual;
        if (!pure) {
            areStatePropsEqual = function () { return false; }; // if pure=false, we respect areStatesEqual logic, but still rerender if state mapper returns same data
        }
        var ConnectedComponent = reactRedux.connect(makeMapStateToProps(storesArr, mapStateToProps), mapDispatchToProps, mergeProps, {
            areStatesEqual: function (next, prev) {
                var _a, _b, _c, _d;
                var nextIsDrag = (_b = (_a = next.state) === null || _a === void 0 ? void 0 : _a.mouseActions) === null || _b === void 0 ? void 0 : _b.dragInProgress;
                var prevIsDrag = (_d = (_c = prev.state) === null || _c === void 0 ? void 0 : _c.mouseActions) === null || _d === void 0 ? void 0 : _d.dragInProgress;
                if (nextIsDrag && prevIsDrag && !mouseConnected) {
                    return true;
                }
                if ((dsConnected && stateConnected) || editorAPIConnected) {
                    return dsAndState(next, prev);
                }
                else if (dsConnected) {
                    return dsOnly(next, prev);
                }
                return next.state === prev.state;
            },
            areStatePropsEqual: areStatePropsEqual,
            areOwnPropsEqual: propsDeepEqual,
            forwardRef: true,
        })(Component);
        var ResultComponent = typeof withErrorBoundary === 'function'
            ? withConnectedErrorBoundary(ConnectedComponent, withErrorBoundary)
            : ConnectedComponent;
        // Looks like it's a community standard:
        // https://github.com/reduxjs/react-redux/blob/791e00945558eb1586d719b51666493276c8d63d/src/components/connect.tsx#L805
        // https://github.com/wix-private/santa-editor/blob/536d4d79e5c04146d8459a27c018cd46b6ff5931/santa-editor/packages/testUtils/index.ts#L62
        ResultComponent.WrappedComponent = Component;
        return ResultComponent;
    };
};
export var connectOld = function (storesArr, mapStateToProps, mapDispatchToProps, shouldHandleStateChanges) {
    if (mapStateToProps === void 0) { mapStateToProps = _.noop; }
    if (mapDispatchToProps === void 0) { mapDispatchToProps = _.noop; }
    if (shouldHandleStateChanges === void 0) { shouldHandleStateChanges = false; }
    return function (Component) {
        var selectorFactory = function (dispatch, _a) {
            var dsRead = _a.dsRead, editorAPI = _a.editorAPI;
            return function (state, ownProps) {
                var mappingDependencies = _.pick({ dsRead: dsRead, state: state, editorAPI: editorAPI }, storesArr);
                var stateProps = mapStateToProps(mappingDependencies, ownProps);
                var dispatchProps = _.isFunction(mapDispatchToProps)
                    ? mapDispatchToProps(dispatch, ownProps)
                    : redux.bindActionCreators(mapDispatchToProps, dispatch);
                return _.assign({}, ownProps, stateProps, dispatchProps);
            };
        };
        return createReactClass({
            displayName: wrapDisplayName(Component, 'Connect'),
            contextTypes: {
                dsRead: PropTypes.object,
                editorAPI: PropTypes.object,
            },
            componentWillMount: function () {
                this.connectedComponentClass = reactRedux.connectAdvanced(selectorFactory, {
                    methodName: 'connect',
                    shouldHandleStateChanges: shouldHandleStateChanges,
                    dsRead: this.context.dsRead,
                    editorAPI: this.context.editorAPI,
                })(Component);
            },
            render: function () {
                return React.createElement(this.connectedComponentClass, this.props);
            },
        });
    };
};
