// @ts-nocheck
import _ from 'lodash';
var URL_REGEX = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
var verifier = function (url) { return _.isString(url) && URL_REGEX.test(url); };
var getEmbeddedUrl = function (url) {
    var videoId = url.match(URL_REGEX)[1];
    return "//www.youtube.com/embed/".concat(videoId, "?wmode=transparent&theme=light&controls=0&autohide=0&loop=1&showinfo=0&autoplay=0");
};
var getUrlFromId = function (videoId) { return "http://www.youtube.com/watch?v=".concat(videoId); };
export { verifier, getEmbeddedUrl, getUrlFromId };
