var _a;
import _ from 'lodash';
import * as dailymotion from './dailymotion';
import * as facebook from './facebook';
import * as file from './file';
import * as twitch from './twitch';
import * as vimeo from './vimeo';
import * as youtube from './youtube';
var VIDEO_PLAYER_TYPES = {
    DAILYMOTION: 'dailymotion',
    FACEBOOK: 'facebook',
    FILE: 'file',
    TWITCH: 'twitch',
    VIMEO: 'vimeo',
    YOUTUBE: 'youtube',
};
var players = (_a = {},
    _a[VIDEO_PLAYER_TYPES.DAILYMOTION] = {
        verifier: dailymotion.verifier,
        getEmbeddedUrl: dailymotion.getEmbeddedUrl,
        getUrlFromId: dailymotion.getUrlFromId,
    },
    _a[VIDEO_PLAYER_TYPES.FACEBOOK] = {
        verifier: facebook.verifier,
        getEmbeddedUrl: facebook.getEmbeddedUrl,
        getUrlFromId: function () { return null; },
    },
    _a[VIDEO_PLAYER_TYPES.FILE] = {
        verifier: file.verifier,
        getEmbeddedUrl: function () { return null; },
        getUrlFromId: function () { return null; },
    },
    _a[VIDEO_PLAYER_TYPES.TWITCH] = {
        verifier: twitch.verifier,
        getEmbeddedUrl: twitch.getEmbeddedUrl,
        getUrlFromId: twitch.getUrlFromId,
    },
    _a[VIDEO_PLAYER_TYPES.VIMEO] = {
        verifier: vimeo.verifier,
        getEmbeddedUrl: vimeo.getEmbeddedUrl,
        getUrlFromId: vimeo.getUrlFromId,
    },
    _a[VIDEO_PLAYER_TYPES.YOUTUBE] = {
        verifier: youtube.verifier,
        getEmbeddedUrl: youtube.getEmbeddedUrl,
        getUrlFromId: youtube.getUrlFromId,
    },
    _a);
var getTypeFromUrl = function (url) {
    return _.findKey(players, function (player) { return player.verifier(url); });
};
var verifyUrl = function (url) { return Boolean(getTypeFromUrl(url)); };
var getEmbeddedUrl = function (type, url) { return players[type].getEmbeddedUrl(url); };
var getUrlFromId = function (type, id) { return players[type].getUrlFromId(id); };
export { getTypeFromUrl, getEmbeddedUrl, getUrlFromId, verifyUrl, VIDEO_PLAYER_TYPES, };
