var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
import { connect, STORES } from '../hoc/connect';
import withBeforeFirstRender from '../hoc/withBeforeFirstRender';
import consts from './stylableChangeConsts';
var STYLESHEET_KEY_PATH = consts.STYLESHEET_KEY_PATH, VARIANT_CLASSNAME = consts.VARIANT_CLASSNAME, COMPONENT_PREFIX = consts.COMPONENT_PREFIX;
export var getVariantPath = function (variant) { return "/".concat(variant, ".st.css"); };
export var getPanelId = function (compType) {
    var prefix = COMPONENT_PREFIX.find(function (prefix) { return compType.startsWith(prefix); });
    return prefix ? compType.slice(prefix.length) : compType;
};
export var getSelectedComponents = function (selectedComponentOrComponents) {
    return Array.isArray(selectedComponentOrComponents)
        ? selectedComponentOrComponents
        : [selectedComponentOrComponents];
};
var mobileMediaQueryVarName = 'mobileMediaQuery';
var MOBILE_MEDIA_QUERY = "@media value(".concat(mobileMediaQueryVarName, ")");
var variantPointerOnLoad;
var selectedComponentOnLoad;
/**
 *
 * @param editorAPI
 * @param selectedComponent - CompRef
 * @returns
 */
function getCascadePaths(editorAPI, selectedComponent) {
    // Get cascade styleSheet paths from variant array:
    var cascadeVariants = editorAPI.components.dangerouslyGetCascadeVariants(selectedComponent);
    if (cascadeVariants) {
        var cascadeStyleIds = cascadeVariants.reduce(function (accum, variant) {
            var variantStyleItem = editorAPI.dsRead.components.style.get(variant);
            if (variantStyleItem) {
                accum.push(variantStyleItem.id);
            }
            return accum;
        }, []);
        cascadeStyleIds.reverse();
        return cascadeStyleIds.map(function (styleId) { return getVariantPath(styleId); });
    }
    return undefined;
}
var hasResponsiveLayout = function (editorAPI, compRef) {
    var _a;
    return Boolean(compRef && ((_a = editorAPI.dsRead.components.responsiveLayout) === null || _a === void 0 ? void 0 : _a.get(compRef)));
};
var shouldUseOriginalStyleItemOnly = function (selectedComponent, editorAPI) {
    var isMobileEditor = editorAPI.isMobileEditor();
    var fixStylableInMobileExperiment = _.get(window, 'editorModel.runningExperiments.se_fixStylableInMobileEditor');
    var isFixStylableInMobileEditorExperimentOn = fixStylableInMobileExperiment === 'true' ||
        fixStylableInMobileExperiment === 'new';
    return (isMobileEditor &&
        isFixStylableInMobileEditorExperimentOn &&
        !hasResponsiveLayout(editorAPI, selectedComponent));
};
var getStyleDataItem = function (selectedComponent, editorAPI, useOriginalStyleItem) {
    if (useOriginalStyleItem === void 0) { useOriginalStyleItem = false; }
    return useOriginalStyleItem
        ? editorAPI.documentServices.components.style.get(selectedComponent)
        : editorAPI.components.style.get(selectedComponent);
};
export var withStylableChange = function () {
    var mapStateToProps = function (_a, props) {
        var editorAPI = _a.editorAPI;
        var multiSelectedComponents = props.multiSelectedComponents;
        var selectedComponents = getSelectedComponents(props.selectedComponent);
        var selected = multiSelectedComponents || selectedComponents;
        var components = editorAPI.documentServices.components;
        var isMobileEditor = editorAPI.isMobileEditor();
        var stylableEditor = components.stylable.getEditorInstance();
        var selectedComponent = _.head(selected);
        var useOriginalStyleItem = shouldUseOriginalStyleItemOnly(selectedComponent, editorAPI);
        var styleDataItem = getStyleDataItem(selectedComponent, editorAPI, useOriginalStyleItem);
        var styleId = styleDataItem.id;
        var cascadePaths = useOriginalStyleItem
            ? undefined
            : getCascadePaths(editorAPI, selectedComponent);
        var withSelection = function (callback) {
            var selection = stylableEditor.getSelection(0);
            return selection ? callback(selection) : null;
        };
        var updateCompStyle = function (newCss, compRef, styleDefinition) {
            if (styleDefinition === void 0) { styleDefinition = styleDataItem; }
            return editorAPI.components.stylable.update(compRef, _.defaultsDeep(_.set({}, STYLESHEET_KEY_PATH, stylableEditor.getMinimalCss(newCss)), styleDefinition));
        };
        var updateStyle = function (newCss) {
            if (newCss && selected.length === 1) {
                var compRef = selected[0];
                return updateCompStyle(newCss, compRef, editorAPI.components.style.get(compRef));
            }
            selected.forEach(function (compRef) {
                var _a;
                var styleDefinition = components.style.get(compRef);
                var path = getVariantPath(styleDefinition.id);
                var selection = stylableEditor === null || stylableEditor === void 0 ? void 0 : stylableEditor.getSelection(0);
                var stylesheet = (_a = selection === null || selection === void 0 ? void 0 : selection.stylableDriver) === null || _a === void 0 ? void 0 : _a.getStylesheet(path);
                if (stylesheet) {
                    updateCompStyle(stylesheet.source, compRef, styleDefinition);
                }
            });
        };
        var partialChangeCallback = function (changes) {
            var _a;
            if (multiSelectedComponents) {
                var selectedComponentPaths = multiSelectedComponents.map(function (compRef) {
                    var styleDefinition = editorAPI.components.style.get(compRef);
                    return getVariantPath(styleDefinition.id);
                });
                var stylesheetChanges_1 = [];
                selectedComponentPaths.forEach(function (stylesheetPath) {
                    changes.forEach(function (change) {
                        return stylesheetChanges_1.push(__assign(__assign({}, change), { stylesheetPath: stylesheetPath }));
                    });
                });
                (_a = stylableEditor === null || stylableEditor === void 0 ? void 0 : stylableEditor.getSelection(0)) === null || _a === void 0 ? void 0 : _a.applyChangesToStylesheets(stylesheetChanges_1);
            }
            return !!multiSelectedComponents;
        };
        var onQuickChange = function (selector, declarationMap) {
            selected.forEach(function (compRef) {
                return components.stylable.quickChange(compRef, selector, declarationMap);
            });
        };
        var onRevertQuickChange = function () {
            selected.forEach(function (compRef) {
                return components.stylable.revertQuickChange(compRef);
            });
        };
        var addToUndoRedoStack = function () {
            var STYLE_CHANGE_STACK_LABEL = consts.STYLE_CHANGE_STACK_LABEL;
            var isMobileEditor = editorAPI.isMobileEditor();
            editorAPI.history.debouncedAdd("".concat(STYLE_CHANGE_STACK_LABEL, " : ").concat(styleId), {
                currentPage: editorAPI.pages.getCurrentPageId(),
                isMobileEditor: isMobileEditor,
            });
        };
        var formatIndexCssVar = function (varName, componentName) {
            return "--".concat(componentName, "-").concat(varName);
        };
        return {
            stylableEditor: stylableEditor,
            variant: styleId,
            loadVariant: function (compType, callbacks) {
                if (callbacks === void 0) { callbacks = {}; }
                // change this API. callbacks shouldn't be passed like this, and loadVariant should be called from withStylableChange instead of from the panel
                // TODO after using new ReloadVariant API we can get rid of handleOnChange and its derivatives.
                var handleOnChange = callbacks.handleOnChange;
                if (handleOnChange) {
                    stylableEditor.stylableDriver.registerBuildHook(handleOnChange);
                }
                stylableEditor.load({
                    path: getVariantPath(styleId),
                    id: getPanelId(compType),
                    variant: VARIANT_CLASSNAME,
                    scope: isMobileEditor ? MOBILE_MEDIA_QUERY : undefined,
                    onQuickChange: onQuickChange,
                    onRevertQuickChange: onRevertQuickChange,
                    inputPaths: cascadePaths,
                });
                variantPointerOnLoad =
                    editorAPI.components.getSelectedCompVariantPointerIfExists();
                selectedComponentOnLoad = selectedComponent;
                withSelection(function (selection) {
                    selection.changeDriver.setOnStylesheetCommit(addToUndoRedoStack);
                    if (multiSelectedComponents) {
                        selection.changeDriver.setOnPartialChange(partialChangeCallback);
                    }
                });
            },
            unloadVariant: function (handleOnChange) {
                // TODO after using new ReloadVariant API we can get rid of handleOnChange and its derivatives.
                if (handleOnChange) {
                    stylableEditor.stylableDriver.unregisterBuildHook(handleOnChange);
                }
                withSelection(
                // TODO: make this official api to remove the callback
                function (selection) {
                    selection.changeDriver.setOnStylesheetCommit(undefined);
                });
                editorAPI.components.stylable.removeRefIfEmptyStyle(selectedComponentOnLoad, variantPointerOnLoad); //using saved variant pointer to prevent "removeRef" on new selected comp. Happens when clicking outside panel. see https://jira.wixpress.com/browse/STYL-667
            },
            getSelectorDeclarations: function (selector) {
                return withSelection(function (selection) {
                    return selection.getSelectorDeclarations(selector);
                });
            },
            setSelectorDeclarations: function (selector, declarations) {
                return withSelection(function (selection) {
                    return selection.setSelectorDeclarations(selector, declarations);
                });
            },
            ensureImportedCssVars: function (varNames) {
                return withSelection(function (selection) {
                    return selection.ensureImportedCssVars(varNames, formatIndexCssVar);
                });
            },
            blockCommits: function () {
                return withSelection(function (selection) {
                    return selection.changeDriver.startBlockingCommits();
                });
            },
            releaseCommits: function () {
                return withSelection(function (selection) {
                    return selection.changeDriver.releaseBlockingCommits();
                });
            },
            updateStyle: updateStyle,
            updateStyleFromSourceCss: function () {
                return withSelection(function (selection) { return updateStyle(selection.getSourceCSS()); });
            },
            getStyleCss: function () { return _.get(styleDataItem, STYLESHEET_KEY_PATH); },
            addToUndoRedoStack: addToUndoRedoStack,
            createEmptyStyle: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            editorAPI.components.stylable.createEmptyStyle(selectedComponent);
                            return [4 /*yield*/, new Promise(function (resolve) {
                                    editorAPI.waitForChangesApplied(resolve);
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); },
        };
    };
    return _.flow(withBeforeFirstRender(function (_a) {
        var createEmptyStyle = _a.createEmptyStyle;
        return createEmptyStyle();
    }), // TODO can remove after using withReloadVariant in wix-ui-santa/ee components
    connect(STORES.EDITOR_API, mapStateToProps));
};
