import _ from 'lodash';
function isDomNode(node) {
    return (typeof _.get(node, 'nodeType') === 'number' &&
        typeof _.get(node, 'nodeName') === 'string');
}
function getDomelement(elem) {
    var domNodeFromZeptoElem = _.get(elem, 0);
    if (isDomNode(domNodeFromZeptoElem)) {
        return domNodeFromZeptoElem;
    }
    if (isDomNode(elem)) {
        return elem;
    }
    return isDomNode(_.get(elem, '0')) && elem;
}
function getDomElementProperty(elem, property) {
    var domElement = getDomelement(elem);
    if (!domElement) {
        return 0;
    }
    return domElement[property];
}
function getOuterWidth(elem) {
    return getDomElementProperty(elem, 'offsetWidth') || 0;
}
function getOuterHeight(elem) {
    return getDomElementProperty(elem, 'offsetHeight') || 0;
}
function setOffset($element, top, left) {
    var curOffset = $element.offset();
    var curTop = parseFloat($element.css('top')) || 0;
    var curLeft = parseFloat($element.css('left')) || 0;
    top = top - curOffset.top + curTop;
    left = left - curOffset.left + curLeft;
    $element.css({ top: top, left: left });
}
export { getOuterWidth, getOuterHeight, setOffset };
