import _ from 'lodash';
var combineValidators = function () {
    var validationFunctions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validationFunctions[_i] = arguments[_i];
    }
    return function (value) {
        return _.reduce(validationFunctions, function (isValid, validationFunction) { return isValid && validationFunction(value); }, true);
    };
};
export { combineValidators };
