var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
var withErrorBoundary = function (Component, _a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, subscribeUpdates = _c.subscribeUpdates;
    var componentDisplayName = Component.displayName || Component.name;
    return _b = /** @class */ (function (_super) {
            __extends(ErrorBoundaryComponent, _super);
            function ErrorBoundaryComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.state = {
                    hasError: false,
                };
                _this._attemptToRecover = _.throttle(function () {
                    var _a, _c;
                    (_c = (_a = _this.state).cancelAttemptsToRecover) === null || _c === void 0 ? void 0 : _c.call(_a);
                    _this.setState(function (_a) {
                        var _c = _a.attemptsToRecover, attemptsToRecover = _c === void 0 ? 0 : _c;
                        return ({
                            attemptsToRecover: attemptsToRecover + 1,
                            cancelAttemptsToRecover: null,
                            hasError: false,
                        });
                    });
                }, 500, {
                    leading: true,
                });
                return _this;
            }
            ErrorBoundaryComponent.getDerivedStateFromError = function () {
                return { hasError: true };
            };
            ErrorBoundaryComponent.prototype.componentDidCatch = function (error, info) {
                // eslint-disable-next-line no-console
                console.error(error, info);
                ErrorReporter.captureException(error, {
                    tags: {
                        throwingComponent: componentDisplayName,
                    },
                    extra: {
                        errorInfo: info,
                        attemptsToRecover: this.state.attemptsToRecover,
                    },
                });
                this.initAttemptsToRecover();
            };
            ErrorBoundaryComponent.prototype.componentWillUnmount = function () {
                var _a, _c;
                (_c = (_a = this.state).cancelAttemptsToRecover) === null || _c === void 0 ? void 0 : _c.call(_a);
            };
            ErrorBoundaryComponent.prototype.initAttemptsToRecover = function () {
                var _this = this;
                if (typeof subscribeUpdates !== 'function' ||
                    // NOTE: recover attempts are already initialized
                    typeof this.state.cancelAttemptsToRecover === 'function') {
                    return;
                }
                // TODO: implement recover limit for the case when every recover failed
                // ensureAttemptsToRecoverIsWithinLimit(this.state.attemptsToRecover);
                var unsubscribeUpdates = subscribeUpdates(this._attemptToRecover);
                this.setState({
                    cancelAttemptsToRecover: function () {
                        unsubscribeUpdates();
                        _this._attemptToRecover.cancel();
                    },
                });
            };
            ErrorBoundaryComponent.prototype.render = function () {
                var _a = this, state = _a.state, props = _a.props;
                if (state.hasError) {
                    return null;
                }
                return React.createElement(Component, __assign(__assign({}, props), { 
                    // https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
                    ref: props.ref || props.forwardedRef }));
            };
            return ErrorBoundaryComponent;
        }(React.Component)),
        _b.displayName = "errorBoundaryComponent(".concat(componentDisplayName, ")"),
        _b;
};
export default withErrorBoundary;
