import _ from 'lodash';
import * as imageKit from '@wix/image-kit';
import url from '../data/url';
var fittingTypes = imageKit.fittingTypes, alignTypes = imageKit.alignTypes;
function init() {
    imageKit.populateGlobalFeatureSupport();
}
/**
 * Call Santa imageTransform
 */
function getData(fittingType, src, target, imageFilters) {
    target.width = parseInt(target.width.toString(), 10);
    target.height = parseInt(target.height.toString(), 10);
    return imageKit.getData(fittingType, src, target, imageFilters);
}
/**
 * If the image is larger than the container on all sides - fill, else fit
 */
function getImageScale(imageData, containerWidth, containerHeight) {
    if (imageData.width >= containerWidth &&
        imageData.height >= containerHeight) {
        return imageKit.fittingTypes.SCALE_TO_FILL;
    }
    return imageKit.fittingTypes.SCALE_TO_FIT;
}
/**
 * Get image full path and css from imageServices from an imageData object
 */
function getFromImageData(imageData, containerWidth, containerHeight, fittingType, alignType, htmlTag) {
    if (fittingType === void 0) { fittingType = imageKit.fittingTypes.SCALE_TO_FILL; }
    if (alignType === void 0) { alignType = imageKit.alignTypes.CENTER; }
    if (htmlTag === void 0) { htmlTag = 'img'; }
    var imageFilters = imageData.quality || {};
    var devicePixelRatio = imageFilters.devicePixelRatio || window.devicePixelRatio;
    // @ts-expect-error
    var mediaUrlPrefix = window.serviceTopology.staticMediaUrl || '';
    var src = _.assign(_.pick(imageData, ['width', 'height', 'crop']), {
        id: imageData.uri,
    });
    var target = {
        width: containerWidth,
        height: containerHeight,
        htmlTag: htmlTag,
        pixelAspectRatio: devicePixelRatio,
        alignment: alignType,
    };
    var imageComputedProperties = getData(fittingType, src, target, imageFilters);
    if (!url.isExternalUrl(imageComputedProperties.uri)) {
        imageComputedProperties.uri = url.joinURL(mediaUrlPrefix, imageComputedProperties.uri);
    }
    return imageComputedProperties;
}
export { getData, getFromImageData, fittingTypes, alignTypes, getImageScale, init, };
