import _ from 'lodash';
import { getMiddlePoint } from './rect';
import * as rectUtil from './rect';
import coreUtilsLib from '@wix/santa-core-utils';
import { normalizeNumber } from './normalizeNumber';
function xywhToVectors(sq) {
    return {
        x: { min: sq.x, max: sq.x + sq.width },
        y: { min: sq.y, max: sq.y + sq.height },
    };
}
function vectorsToxywh(v) {
    return {
        x: v.x.min,
        y: v.y.min,
        width: v.x.max - v.x.min,
        height: v.y.max - v.y.min,
    };
}
function valueInValidRange(value, min, max) {
    return min <= value && value <= max;
}
//////////////////////////////////////////////////////////
function valueFromPercent(percent, min, max) {
    if (!valueInValidRange(percent, 0, 100) || max < min) {
        coreUtilsLib.log.error('percent is invalid of range or range error');
        return null;
    }
    return coreUtilsLib.math.map(percent, 0, 100, min, max);
}
function percentFromValue(value, min, max) {
    if (!valueInValidRange(value, min, max)) {
        coreUtilsLib.log.error('value is out of range or range error');
        return null;
    }
    return coreUtilsLib.math.map(value, min, max, 0, 100);
}
function ensureWithinLimits(value, min, max) {
    min = _.isUndefined(min) ? value : min;
    max = _.isUndefined(max) ? value : max;
    if (min > max) {
        throw new Error("min limit is greater than max limit: ".concat(min, ", ").concat(max));
    }
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
}
function getSquaresIntersection(sq1, sq2) {
    var vs1 = xywhToVectors(sq1);
    var vs2 = xywhToVectors(sq2);
    var intersection = {
        x: {
            min: Math.max(vs1.x.min, vs2.x.min),
            max: Math.min(vs1.x.max, vs2.x.max),
        },
        y: {
            min: Math.max(vs1.y.min, vs2.y.min),
            max: Math.min(vs1.y.max, vs2.y.max),
        },
    };
    var sq = vectorsToxywh(intersection);
    return sq.width > 0 && sq.height > 0 ? sq : null;
}
function getQuadraticEquationBy3Points(p1, p2, p3) {
    // Lagrange Polynomial Interpolation
    // https://en.wikipedia.org/wiki/Polynomial_interpolation
    var a = p1.y / ((p1.x - p2.x) * (p1.x - p3.x)) +
        p2.y / ((p2.x - p1.x) * (p2.x - p3.x)) +
        p3.y / ((p3.x - p1.x) * (p3.x - p2.x));
    var b = (-p1.y * (p2.x + p3.x)) / ((p1.x - p2.x) * (p1.x - p3.x)) -
        (p2.y * (p1.x + p3.x)) / ((p2.x - p1.x) * (p2.x - p3.x)) -
        (p3.y * (p1.x + p2.x)) / ((p3.x - p1.x) * (p3.x - p2.x));
    var c = (p1.y * p2.x * p3.x) / ((p1.x - p2.x) * (p1.x - p3.x)) +
        (p2.y * p1.x * p3.x) / ((p2.x - p1.x) * (p2.x - p3.x)) +
        (p3.y * p1.x * p2.x) / ((p3.x - p1.x) * (p3.x - p2.x));
    var bSquared = Math.pow(b, 2);
    function getQuadraticEquationMaxSolution(y) {
        if (a === 0) {
            return (y - c) / b;
        }
        // Quadratic Formula
        var delta = Math.sqrt(bSquared - 4 * a * (c - y));
        return Math.max((-b - delta) / (2 * a), (-b + delta) / (2 * a));
    }
    function getQuadraticEquationYByX(x) {
        return a * Math.pow(x, 2) + b * x + c;
    }
    return {
        fInverse: getQuadraticEquationMaxSolution,
        f: getQuadraticEquationYByX,
    };
}
function degToRad(deg) {
    return (deg / 360) * (Math.PI * 2);
}
function rotateVectorAround(p, rad) {
    return {
        x: p.x * Math.cos(rad) - p.y * Math.sin(rad),
        y: p.x * Math.sin(rad) + p.y * Math.cos(rad),
    };
}
function roundToNearestHalf(num) {
    return Math.round(num * 2) / 2;
}
function getNormalizedDegrees(deg) {
    var degBeforeRepeat = deg % 180;
    return degBeforeRepeat > 90 ? 180 - degBeforeRepeat : degBeforeRepeat;
}
function getBoundingRectAfterRotation(rect, deg) {
    var normalizedRect = _.assign({}, rect, { x: 0, y: 0 });
    var vectorToBottomRight = getMiddlePoint(normalizedRect);
    var vectorToTopRight = _.assign({}, vectorToBottomRight, {
        y: -vectorToBottomRight.y,
    });
    var normalizedDegrees = getNormalizedDegrees(deg);
    var rad = degToRad(180 + normalizedDegrees);
    var vectorToRotatedRectTopLeft = rotateVectorAround(vectorToBottomRight, rad);
    var vectorToRotatedRectBottomLeft = rotateVectorAround(vectorToTopRight, rad);
    var yDiff = vectorToRotatedRectTopLeft.y + rect.height / 2;
    var xDiff = vectorToRotatedRectBottomLeft.x + rect.width / 2;
    var boundingBox = {
        x: rect.x + xDiff,
        y: rect.y + yDiff,
        width: rect.width - 2 * xDiff,
        height: rect.height - 2 * yDiff,
    };
    return _.mapValues(boundingBox, roundToNearestHalf);
}
function getPointAsIfRectWasNotRotated(rect, p, deg) {
    var rectCenter = getMiddlePoint(rect);
    var vector = {
        x: p.x - rectCenter.x,
        y: p.y - rectCenter.y,
    };
    var rad = degToRad(-deg);
    var rotatedVector = rotateVectorAround(vector, rad);
    var normalizedPoint = {
        x: rotatedVector.x + rectCenter.x,
        y: rotatedVector.y + rectCenter.y,
    };
    return _.mapValues(normalizedPoint, roundToNearestHalf);
}
function numberFromValue(newValue) {
    if (newValue === '') {
        return null;
    }
    return Number(newValue);
}
export { valueFromPercent, percentFromValue, ensureWithinLimits, getSquaresIntersection, getQuadraticEquationBy3Points, degToRad, rotateVectorAround, roundToNearestHalf, rectUtil as rect, getBoundingRectAfterRotation, getPointAsIfRectWasNotRotated, numberFromValue, normalizeNumber, };
