var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var imageMimeType = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/jpe',
    'image/jfif',
    'image/bmp',
    'image/heic',
    'image/heif',
    'image/tiff',
    'image/tif',
    'image/webp',
    'image/jp2',
    'image/jpg2',
    'image/j2k',
    'image/jpf',
    'image/jpm',
    'image/j2c',
    'image/jpc',
    'image/jpx',
    'image/arw',
    'image/srw',
    'image/nef',
    'image/cr2',
    'image/cr3',
    'image/crw',
    'image/rwl',
    'image/rw2',
    'image/raw',
    'image/raf',
    'image/pef',
    'image/orf',
    'image/mrw',
    'image/dng',
    'image/sr2',
    'image/srf',
    'image/kdc',
    'image/k25',
    'image/dcr',
    'image/x3f',
    'image/erf',
    'image/3fr',
];
export var isImageMimeType = function (mimeType) {
    return imageMimeType.includes(mimeType);
};
function getBaseUrl(windowObj) {
    var _a;
    return "".concat((_a = windowObj === null || windowObj === void 0 ? void 0 : windowObj.serviceTopology) === null || _a === void 0 ? void 0 : _a.mediaUploadServerUrl, "site/media/files/upload/url?");
}
export var mediaTypesTranslation = {
    'image/jpg': 'picture',
    'image/png': 'picture',
    'image/gif': 'picture',
    'image/jpeg': 'picture',
    'image/jpe': 'picture',
    'image/jfif': 'picture',
    'image/bmp': 'picture',
    'image/heic': 'picture',
    'image/heif': 'picture',
    'image/tiff': 'picture',
    'image/tif': 'picture',
    'image/webp': 'picture',
    'image/jp2': 'picture',
    'image/jpg2': 'picture',
    'image/j2k': 'picture',
    'image/jpf': 'picture',
    'image/jpm': 'picture',
    'image/j2c': 'picture',
    'image/jpc': 'picture',
    'image/jpx': 'picture',
    'image/arw': 'picture',
    'image/srw': 'picture',
    'image/nef': 'picture',
    'image/cr2': 'picture',
    'image/cr3': 'picture',
    'image/crw': 'picture',
    'image/rwl': 'picture',
    'image/rw2': 'picture',
    'image/raw': 'picture',
    'image/raf': 'picture',
    'image/pef': 'picture',
    'image/orf': 'picture',
    'image/mrw': 'picture',
    'image/dng': 'picture',
    'image/sr2': 'picture',
    'image/srf': 'picture',
    'image/kdc': 'picture',
    'image/k25': 'picture',
    'image/dcr': 'picture',
    'image/x3f': 'picture',
    'image/erf': 'picture',
    'image/3fr': 'picture',
    'video/avi': 'video',
};
function uploadSiteFile(mediaAuthToken, fileUploadData, tags) {
    return __awaiter(this, void 0, void 0, function () {
        var authorizationToken, paramsObj, params, url, options;
        return __generator(this, function (_a) {
            authorizationToken = "APP ".concat(mediaAuthToken);
            paramsObj = {
                media_type: mediaTypesTranslation[fileUploadData.type],
                file_name: fileUploadData.name,
                mime_type: fileUploadData.type,
            };
            params = Object.entries(paramsObj)
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return "".concat(key, "=").concat(encodeURIComponent(value));
            })
                .join('&');
            url = getBaseUrl(window).concat(params);
            options = {
                method: 'GET',
                headers: {
                    Authorization: authorizationToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };
            return [2 /*return*/, fetch(url, options)
                    .then(function (data) { return data.json(); })
                    .then(function (_a) {
                    var upload_token = _a.upload_token, upload_url = _a.upload_url;
                    return uploadFileToMediaPlatform(fileUploadData, upload_url, upload_token, tags);
                })];
        });
    });
}
function uploadFileToMediaPlatform(blob, upload_url, upload_token, tags) {
    return __awaiter(this, void 0, void 0, function () {
        var formData, options;
        return __generator(this, function (_a) {
            formData = new FormData();
            formData.append('upload_token', upload_token);
            formData.append('media_type', mediaTypesTranslation[blob.type]);
            formData.append('file', blob);
            if (tags)
                formData.append('tags', tags.toString());
            options = {
                method: 'POST',
                body: formData,
                processData: false,
                'sec-fetch-site': 'cross-site',
                'sec-fetch-mode': 'cors',
                contentType: false,
            };
            return [2 /*return*/, fetch(upload_url, options)
                    .then(function (data) { return data.json(); })
                    .then(function (data) { return data[0]; })];
        });
    });
}
export default uploadSiteFile;
