var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from './connect';
var shouldRenderKey = '__shouldRender__';
var withConditionalRender = function (stores, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
shouldRender) {
    if (shouldRender === void 0) { shouldRender = function (stores) { return true; }; }
    return function (CompClass) {
        var WithConditionalRender = /** @class */ (function (_super) {
            __extends(WithConditionalRender, _super);
            function WithConditionalRender() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            WithConditionalRender.prototype.render = function () {
                if (this.props[shouldRenderKey]) {
                    return React.createElement(CompClass, __assign({}, _.omit(this.props, shouldRenderKey)));
                }
                return null;
            };
            return WithConditionalRender;
        }(Component));
        //@ts-expect-error
        WithConditionalRender.propTypes = {
            stores: PropTypes.object,
        };
        var mapStateToProps = function (stateMapperArgs) {
            var _a;
            return _a = {},
                _a[shouldRenderKey] = shouldRender(stateMapperArgs),
                _a;
        };
        return connect(stores, mapStateToProps)(WithConditionalRender);
    };
};
export default withConditionalRender;
