// @ts-nocheck
import _ from 'lodash';
var VIDEO_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
var CHANNEL_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;
var verifier = function (url) {
    return _.isString(url) && (VIDEO_URL_REGEX.test(url) || CHANNEL_URL_REGEX.test(url));
};
var getEmbeddedUrl = function (url) {
    var isChannel = CHANNEL_URL_REGEX.test(url);
    var videoId = isChannel
        ? url.match(CHANNEL_URL_REGEX)[1]
        : url.match(VIDEO_URL_REGEX)[1];
    var type = isChannel ? 'channel' : 'video';
    var hostname = window.location.hostname;
    return "https://player.twitch.tv/?".concat(type, "=").concat(videoId, "&muted=true&autoplay=false&parent=").concat(hostname);
};
var getUrlFromId = function (videoId) { return "https://www.twitch.tv/videos/".concat(videoId); };
export { verifier, getEmbeddedUrl, getUrlFromId };
