import _ from 'lodash';
import { textinputValidators } from './textinputValidators';
var widgetNameValidator = function (widgetName, excludedNames) {
    if (excludedNames === void 0) { excludedNames = []; }
    var validExpression = /^[A-Za-z0-9]*$/;
    if (!textinputValidators.notEmptyString(widgetName)) {
        return 'AppStudio_LeftTree_Creator_Widget_Rename_Error';
    }
    if (!textinputValidators.noHTMLTags(widgetName) ||
        !textinputValidators.noEmoji(widgetName)) {
        return 'AppStudio_LeftTree_Creator_Widget_Rename_Error';
    }
    if (_.includes(excludedNames, widgetName)) {
        return 'AppStudio_LeftTree_Creator_Widget_Rename_Error';
    }
    if (!validExpression.test(widgetName)) {
        return 'AppStudio_LeftTree_Creator_Widget_Rename_Error';
    }
    return '';
};
export { widgetNameValidator };
