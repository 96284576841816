// @ts-nocheck
import _ from 'lodash';
var URL_REGEX = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;
var verifier = function (url) {
    if (_.isString(url)) {
        return URL_REGEX.test(url);
    }
    else if (_.isArray(url)) {
        return url.some(function (item) { return URL_REGEX.test(item); });
    }
    return false;
};
export { verifier };
