// @ts-nocheck
import _ from 'lodash';
var URL_REGEX = /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/;
var verifier = function (url) { return _.isString(url) && URL_REGEX.test(url); };
var getEmbeddedUrl = function (url) {
    var videoId = url.match(URL_REGEX)[1];
    return "//www.dailymotion.com/embed/video/".concat(videoId, "?controls=1&sharing-enable=0&ui-logo=0&ui-start-screen-info=0");
};
var getUrlFromId = function (videoId) { return "http://dailymotion.com/video/".concat(videoId); };
export { verifier, getEmbeddedUrl, getUrlFromId };
