import _ from 'lodash';
import validationExpressions from './validationExpressions';
import { CREDIT_CARD_KEYWORDS, PASSWORD_KEYWORDS } from './pwdKeywords';
function oldAndNewValueValidation(oldValue, newValue) {
    return !oldValue && newValue === '';
}
var textinputValidators = {
    /**
     * validation code: V1
     * @param val
     * @returns {boolean}
     */
    email: function (val) {
        if (!val) {
            return true;
        }
        return validationExpressions.validEmailReg.test(val);
    },
    /**
     * validation code: V2
     * @param val
     * @returns {boolean}
     */
    url: function (val) {
        return validationExpressions.validUrlReg.test(val);
    },
    /**
     * validation code: V3 (force https)
     * @param val
     * @returns {boolean}
     */
    urlEmbed: function (val) {
        return validationExpressions.validUrlEmbed.test(val);
    },
    /**
     * validation code: V4, V5 (this is both minimum and maximum)
     * @param min
     * @param max
     * @returns {Function}
     */
    range: function (min, max) {
        return function (val) {
            return !_.isUndefined(val) && val.length >= min && val.length <= max;
        };
    },
    /**
     * validation code: V5
     * @param val
     * @returns {boolean}
     */
    number: function (val) {
        return (!_.isEmpty(val) && validationExpressions.validNumericValueReg.test(val));
    },
    /**
     * validation code: V6
     * @param val
     * @returns {boolean}
     */
    videoUrl: function (val) {
        return validationExpressions.validVideoUrlReg.test(val);
    },
    /**
     * validation code: V6
     * @param val
     * @returns {boolean}
     */
    videoUrlDifferentFormats: function (val) {
        return (validationExpressions.validYouTubeLongUrlReg.test(val) ||
            validationExpressions.validYouTubeShortUrlReg.test(val) ||
            validationExpressions.validVimeoUrlReg.test(val) ||
            validationExpressions.validDailymotionUrlReg.test(val) ||
            validationExpressions.validFacebookUrlReg.test(val));
    },
    /**
     * validation code: V9
     * @param val
     * @returns {boolean}
     */
    pinterestPinLink: function (val) {
        return validationExpressions.validPinItUrl.test(val.trim().toLowerCase());
    },
    /**
     * validation code: V14
     * @param val
     * @returns {boolean}
     */
    pinterestFollowUrl: function (val) {
        return validationExpressions.validatePinterestUrl.test(val.trim());
    },
    /**
     * validation code: V19
     * @param val
     * @returns {boolean}
     */
    twitterUser: function (val) {
        return validationExpressions.validTwitterUser.test(val.trim().toLowerCase());
    },
    facebookLink: function (val) {
        return !validationExpressions.validFacebookPageId.test(val);
    },
    disqusId: function (val) {
        return validationExpressions.validDisqusId.test(val.toLowerCase());
    },
    notEmpty: function (val) {
        return !_.isEmpty(val);
    },
    noHTMLTags: function (val) {
        return !/<|>/.test(val);
    },
    byInvalidCharacters: function (invalidCharacters) {
        return function (val) {
            var regExp = new RegExp(invalidCharacters);
            return !regExp.test(val);
        };
    },
    rangeOfWords: function (min, max, delimiter) {
        return function (val) {
            var wordList = val && val.split(delimiter);
            return (!_.isUndefined(wordList) &&
                wordList.length >= min &&
                wordList.length <= max);
        };
    },
    notEmptyString: function (val) {
        return !/^\s*$/.test(val);
    },
    phone: function (val) {
        return validationExpressions.validPhoneReg.test(val);
    },
    internationalPhone: function (val) {
        return validationExpressions.validInternationalPhoneReg.test(val);
    },
    fullUrl: function (val) {
        return validationExpressions.validFullUrl.test(val);
    },
    noEmoji: function (val) {
        return !validationExpressions.validEmoji.test(val);
    },
    validateMaxLength: function (prevMaxLength, maxLengthVal) {
        // eslint-disable-next-line prefer-regex-literals
        var reg = new RegExp('^\\d+$');
        maxLengthVal = String(maxLengthVal);
        if (oldAndNewValueValidation(prevMaxLength, maxLengthVal)) {
            return true;
        }
        var newMaxLength = parseInt(maxLengthVal, 10);
        return (maxLengthVal.match(reg) && newMaxLength > 0 && newMaxLength < 1000 * 1000);
    },
    variableName: function (str) {
        return validationExpressions.validVariableName.test(str);
    },
    eventName: function (str) {
        return validationExpressions.validEventName.test(str);
    },
    securitySafePasswordName: function (value) {
        return !_.includes(CREDIT_CARD_KEYWORDS, value.trim().toLowerCase());
    },
    securitySafeInputName: function (value) {
        return (!_.includes(PASSWORD_KEYWORDS, value.trim().toLowerCase()) &&
            this.securitySafePasswordName(value));
    },
};
export { textinputValidators };
