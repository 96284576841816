var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { object, func } from 'prop-types';
import { ErrorReporter } from '@wix/editor-error-reporter';
export var measuring = function (Comp) { var _a; return _a = /** @class */ (function (_super) {
        __extends(Measuring, _super);
        function Measuring() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                measurements: {},
            };
            _this.updateMeasurements = function (measurements) {
                if (!isEqual(measurements, _this.state.measurements)) {
                    _this.setState({ measurements: measurements });
                }
            };
            return _this;
        }
        Measuring.prototype.componentDidCatch = function (error, info) {
            // eslint-disable-next-line no-console
            console.error('Measuring componentDidCatch', error, info);
            var throwingComponent = Comp.displayName;
            ErrorReporter.captureException(error, {
                tags: {
                    throwingComponent: throwingComponent,
                    measuringWrapper: true,
                },
                extra: {
                    errorInfo: info,
                },
            });
        };
        Measuring.prototype.render = function () {
            return (React.createElement(Comp, __assign({}, this.props, { measurements: this.props.measurements || this.state.measurements, reportMeasurements: this.props.reportMeasurements || this.updateMeasurements })));
        };
        return Measuring;
    }(Component)),
    _a.propTypes = {
        measurements: object,
        reportMeasurements: func,
    },
    _a; };
