// @ts-nocheck
import _ from 'lodash';
var URL_REGEX = /vimeo\.com\/(\d+)/;
var verifier = function (url) { return _.isString(url) && URL_REGEX.test(url); };
var getEmbeddedUrl = function (url) {
    var videoId = url.match(URL_REGEX)[1];
    return "//player.vimeo.com/video/".concat(videoId);
};
var getUrlFromId = function (videoId) { return "https://vimeo.com/".concat(videoId); };
export { verifier, getEmbeddedUrl, getUrlFromId };
