// @ts-nocheck
import _ from 'lodash';
var URL_REGEX = /facebook\.com\/([^/?].+\/)?video(s|\.php)[/?].*$/;
var verifier = function (url) { return _.isString(url) && URL_REGEX.test(url); };
var getEmbeddedUrl = function (url) {
    var urlRegex = /facebook\.com\/(.+\/videos\/[A-Za-z0-9._%-]*)/gi;
    var videoId = urlRegex.exec(url)[1];
    return "//www.facebook.com/plugins/video.php?href=https://www.facebook.com/".concat(videoId, "&width=288&height=162");
};
export { verifier, getEmbeddedUrl };
