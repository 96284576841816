var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as DOMPurify from 'dompurify';
function makePurifyComponent(sanitize) {
    var getHtml = sanitize
        ? function (html) {
            return React.useMemo(function () {
                return DOMPurify.sanitize(html);
            }, [html]);
        }
        : function (html) { return html; };
    var Component = React.forwardRef(function (props, ref) {
        var _a;
        var tag = props.tag, html = props.html, rest = __rest(props, ["tag", "html"]);
        var __html = getHtml(html);
        return React.createElement(tag, __assign(__assign({}, rest), (_a = { ref: ref }, _a['dangerously' + 'SetInnerHTML'] = { __html: __html }, _a)));
    });
    Component.displayName = sanitize
        ? 'SafeInjectHtml'
        : 'ImSureInputIsSafeInjectHtml';
    return Component;
}
// use it only when you 100% sure input is sanitized AND SafeInjectHtml is breaking html
// for 99% of cases, use SafeInjectHtml instead
export var ImSureInputIsSafeInjectHtml = makePurifyComponent(false);
export var SafeInjectHtml = makePurifyComponent(true);
