var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as reactRedux from 'react-redux';
export var withConnectedErrorBoundary = function (Component, withErrorBoundary) {
    var ErrorBoundary;
    // wrapper for:
    // - passing `store.subscribe` as a `subscribeUpdates` function to the `withErrorBoundary`
    // - forwarding the `ref` as a `forwardedRef` to the wrapped component
    var renderErrorBoundary = function (props, ref) {
        var store = reactRedux.useStore();
        ErrorBoundary =
            ErrorBoundary ||
                withErrorBoundary(Component, {
                    subscribeUpdates: store.subscribe,
                });
        return React.createElement(ErrorBoundary, __assign(__assign({}, props), { forwardedRef: ref }));
    };
    return React.forwardRef(renderErrorBoundary);
};
