var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//@ts-nocheck
var geocoder;
var latLngRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
var getGeocoder = function () {
    if (!geocoder) {
        geocoder = new window.google.maps.Geocoder();
    }
    return geocoder;
};
// eslint-disable-next-line consistent-return
var getGeocoderOkStatus = function () {
    if (window.google && window.google.maps) {
        return window.google.maps.GeocoderStatus.OK;
    }
};
var isGeocodingServiceLoaded = function () {
    return !!(window.google && window.google.maps && geocoder);
};
var loadGeocodingService = function (callback) {
    window.onGeocodingServiceLoaded = function () {
        // eslint-disable-next-line no-use-before-define
        if (scriptChild) {
            // eslint-disable-next-line no-use-before-define
            window.document.body.removeChild(scriptChild);
        }
        callback();
    };
    var script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.src =
        '//maps.googleapis.com/maps/api/js?key=AIzaSyDAGW1U0Vmj6sKrGKqTngRvI78Ih48ZBig&sensor=true&' +
            'callback=onGeocodingServiceLoaded';
    var scriptChild = window.document.body.appendChild(script);
};
/**
 * - Calls google api (async) to transform address to lat/long data fields.
 * - Presents a validation error in case no such address is found
 * @param address
 * @param onSuccess
 * @param onError
 */
var getGeoLocationFromAddress = function (address, onSuccess, onError, locationService) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, lat, lng, location_1, searchResults, primaryResult, _b, formattedAddress, geocode, location_2;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (latLngRegex.test(address)) {
                    _a = address.split(', '), lat = _a[0], lng = _a[1];
                    if (lat && lng) {
                        location_1 = {
                            address: address,
                            latitude: Number(lat),
                            longitude: Number(lng),
                        };
                        onSuccess(location_1);
                        return [2 /*return*/];
                    }
                }
                if (!locationService) return [3 /*break*/, 2];
                return [4 /*yield*/, locationService.search({
                        query: address,
                    })];
            case 1:
                searchResults = (_c.sent()).searchResults;
                if (searchResults === null || searchResults === void 0 ? void 0 : searchResults.length) {
                    primaryResult = searchResults[0];
                    if (primaryResult.address) {
                        _b = primaryResult.address, formattedAddress = _b.formattedAddress, geocode = _b.geocode;
                        location_2 = {
                            address: formattedAddress,
                            latitude: geocode === null || geocode === void 0 ? void 0 : geocode.latitude,
                            longitude: geocode === null || geocode === void 0 ? void 0 : geocode.longitude,
                        };
                        return [2 /*return*/, onSuccess(location_2)];
                    }
                }
                return [2 /*return*/, onError('Validation_V13_Google_Maps')];
            case 2:
                getGeocoder().geocode({ address: address }, function (results, status) {
                    if (status === getGeocoderOkStatus()) {
                        var locationCoordinates = results[0].geometry.location;
                        var formattedAddress = results[0].formatted_address;
                        var location_3 = {
                            address: formattedAddress,
                            latitude: locationCoordinates.lat(),
                            longitude: locationCoordinates.lng(),
                        };
                        onSuccess(location_3);
                    }
                    else {
                        onError('Validation_V13_Google_Maps');
                    }
                });
                return [2 /*return*/];
        }
    });
}); };
var validateAddress = function (address, onSuccess, onError, locationService) {
    var convertAddressToGeoLocation = function () {
        getGeoLocationFromAddress(address, onSuccess, onError, locationService);
    };
    if (isGeocodingServiceLoaded()) {
        convertAddressToGeoLocation();
    }
    else {
        loadGeocodingService(convertAddressToGeoLocation);
    }
};
export { validateAddress };
