import _ from 'lodash';
function getArea(rect) {
    return rect.height * rect.width;
}
function getAspectRatio(rect) {
    return rect.height / rect.width;
}
function getRight(rect) {
    return rect.x + rect.width;
}
function getBottom(rect) {
    return rect.y + rect.height;
}
function getResizedByRatio(rect, resizeRatio) {
    var sideRatio = Math.sqrt(resizeRatio);
    return _.assign(_.clone(rect), {
        height: rect.height * sideRatio,
        width: rect.width * sideRatio,
    });
}
function getRightDiff(rect1, rect2) {
    return getRight(rect1) - getRight(rect2);
}
function getBottomDiff(rect1, rect2) {
    return getBottom(rect1) - getBottom(rect2);
}
function getRightAndBottomDiff(rect1, rect2) {
    return {
        x: getRightDiff(rect1, rect2),
        y: getBottomDiff(rect1, rect2),
    };
}
function getMiddlePoint(rect) {
    return {
        x: rect.x + rect.width / 2,
        y: rect.y + rect.height / 2,
    };
}
function getAreaRatioPercentage(rect1, rect2) {
    return (getArea(rect1) / getArea(rect2)) * 100;
}
function getPointRatioFromRect(referencePoint, rect) {
    return {
        x: referencePoint.x / rect.width,
        y: referencePoint.y / rect.height,
    };
}
function getMiddleRelativeRectAreaByPercentage(rect, percentage) {
    var paddingPercentage = (100 - percentage) / 2;
    return {
        x: (rect.width * paddingPercentage) / 100,
        y: (rect.height * paddingPercentage) / 100,
        width: (rect.width * percentage) / 100,
        height: (rect.height * percentage) / 100,
    };
}
function areRectsTheSameSize(rect1, rect2) {
    return rect1.height === rect2.height && rect1.width === rect2.width;
}
function getRectMinDimensionsByContainedInnerRect(originalRect, limitingInnerRect) {
    var originalAspectRatio = getAspectRatio(originalRect);
    return {
        height: limitingInnerRect.width * originalAspectRatio,
        width: limitingInnerRect.height / originalAspectRatio,
    };
}
function getRectByBoundingRect(boundingRect) {
    return {
        x: boundingRect.left,
        y: boundingRect.top,
        width: boundingRect.width,
        height: boundingRect.height,
    };
}
function getRectBoundWithAspectRatio(rect, aspectRatio, maxRect, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.right, right = _c === void 0 ? false : _c, _d = _b.left, left = _d === void 0 ? false : _d, _e = _b.bottom, bottom = _e === void 0 ? false : _e, _f = _b.top, top = _f === void 0 ? false : _f, _g = _b.center, center = _g === void 0 ? false : _g;
    var x = rect.x, y = rect.y;
    var boundingRectSize = Math.max(rect.width, rect.height);
    var width = aspectRatio < 1 ? boundingRectSize * aspectRatio : boundingRectSize;
    var height = aspectRatio < 1 ? boundingRectSize : boundingRectSize / aspectRatio;
    if ((maxRect === null || maxRect === void 0 ? void 0 : maxRect.height) && height > maxRect.height) {
        height = maxRect.height;
        width = maxRect.height * aspectRatio;
    }
    if ((maxRect === null || maxRect === void 0 ? void 0 : maxRect.width) && width > maxRect.width) {
        width = maxRect.width;
        height = maxRect.width / aspectRatio;
    }
    var widthDelta = rect.width - width;
    var heightDelta = rect.height - height;
    if (right) {
        x += widthDelta;
    }
    else if (!left && center) {
        x += widthDelta / 2;
    }
    if (bottom) {
        y += heightDelta;
    }
    else if (!top && center) {
        y += heightDelta / 2;
    }
    return { x: x, y: y, width: width, height: height };
}
export { getArea, getAspectRatio, getRight, getBottom, getResizedByRatio, getRightDiff, getBottomDiff, getRightAndBottomDiff, getMiddlePoint, getAreaRatioPercentage, getPointRatioFromRect, getMiddleRelativeRectAreaByPercentage, getRectByBoundingRect, areRectsTheSameSize, getRectMinDimensionsByContainedInnerRect, getRectBoundWithAspectRatio, };
