var numberSuffixRegex = /\d+$/g;
var statesContainNickname = function (editorAPI, states, nickname) {
    return states.some(function (state) {
        return editorAPI.dsRead.components.code.getNickname(state.pointer) === nickname;
    });
};
var createNumberSuffixedName = function (currentNickname, currentNumberSuffix) {
    var newNumberSuffix = parseInt(currentNumberSuffix, 10) + 1;
    return currentNickname.replace(numberSuffixRegex, "".concat(newNumberSuffix));
};
var recursivelyFindNewValidNickname = function (editorAPI, states, currentNickname) {
    var _a;
    var currentNumberSuffix = (_a = currentNickname.match(numberSuffixRegex)) === null || _a === void 0 ? void 0 : _a[0];
    var newNickname = currentNumberSuffix
        ? createNumberSuffixedName(currentNickname, currentNumberSuffix)
        : "".concat(currentNickname, "2");
    return statesContainNickname(editorAPI, states, newNickname)
        ? recursivelyFindNewValidNickname(editorAPI, states, newNickname)
        : newNickname;
};
export var getDuplicatedStateNickname = function (editorAPI, stateRef, states) {
    var currentNickname = editorAPI.dsRead.components.code.getNickname(stateRef) || null;
    return recursivelyFindNewValidNickname(editorAPI, states, currentNickname);
};
