var menuItemNameValidator = function (label) {
    if (label.length === 0) {
        return 'custom_menu_manage_menu_page_rename_empty_error';
    }
    if (label.length > 40) {
        return 'Is too long //todo: add label ';
    }
    return '';
};
export { menuItemNameValidator };
